import React from 'react'
import { graphql } from 'gatsby'

import Layout from '~components/layout'
import SEO from '~components/seo'
import Search from '~components/search/search'

const FAQ = props => {
  const { section, locale } = props.pageContext
  const { location } = props
  const search = props.data.search.nodes.find(({ node_locale }) => node_locale === locale)

  return (
    <Layout locale={locale} location={location} section={section}>
      <SEO lang={locale} title={search?.metaTitle || search.title} description={search?.metaDescription} />
      <div>
        <hr className='accent-rule' aria-hidden />
        <div className='max-w-page py-8'>
          <h2 className='headline-1'>{search.title}</h2>
        </div>
        <hr className='accent-rule' aria-hidden />
      </div>
      <div id='faqs' className='max-w-page mx-auto'>
        <Search type={props.pageContext.type} displayStyle='question' />
      </div>
    </Layout>
  )
}

export default FAQ

export const pageQuery = graphql`
  {
    search: allContentfulSearchPage(filter: { slug: { eq: "faqs" } }) {
      nodes {
        node_locale
        title
        metaTitle
        metaDescription
      }
    }
  }
`
